import { HttpHeaders } from '@angular/common/http';

/**
 * @description
 * return the header value from the headers object
 * Why we need this function: https://qima.atlassian.net/browse/PL-24303
 * @param {HttpHeaders} headers the headers object
 * @param {string} headerName the header key to get
 * @returns {string | null} the header value or null if not found
 */
export function qpGetHeaderValue(headers: HttpHeaders, headerName: string): string | null {
  const headerKeys = headers.keys();
  const normalizedHeader = headerKeys.find((key): boolean => key.toLowerCase() === headerName.toLowerCase());

  return normalizedHeader ? headers.get(normalizedHeader) : null;
}
